/**
 * @generated SignedSource<<07475d06576962752c2e66640ec36a7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountTableRowFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly code: string | null;
  readonly maxRedemptions: number | null;
  readonly redemptions: {
    readonly totalCount: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Discount_displayValue">;
  readonly " $fragmentType": "DiscountTableRowFragment";
};
export type DiscountTableRowFragment$key = {
  readonly " $data"?: DiscountTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountTableRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscountTableRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRedemptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DiscountRedemptionNodeConnection",
      "kind": "LinkedField",
      "name": "redemptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Discount_displayValue"
    }
  ],
  "type": "Discount",
  "abstractKey": null
};

(node as any).hash = "74a083741a9ddf67b84b262205848d61";

export default node;
