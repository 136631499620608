import FormStore from "@/core/form/store/FormStore"
import { PaidProductTableRowFragment$key } from "@/payment/stripe-integration/upgrade/__generated__/PaidProductTableRowFragment.graphql"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import ProductPricingDetails, {
  ProductPricingDetailsSkeleton,
} from "@/pricing/ProductPricingDetails"
import ProductWithDetails, {
  ProductWithDetailsSkeleton,
} from "@/product/common/ProductWithDetails"
import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModalProps, DiscoTable } from "@disco-ui"
import { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { TableCell } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = Pick<DiscoModalProps, "onClose"> & {
  productKey: PaidProductTableRowFragment$key
  form: FormStore<
    UpgradeCheckoutVersionModalContentState,
    UpgradeCheckoutVersionModalContentMutation
  >
}

function PaidProductTableRow(props: Props) {
  const { productKey } = props
  const classes = useStyles()

  const product = useFragment<PaidProductTableRowFragment$key>(
    graphql`
      fragment PaidProductTableRowFragment on Product {
        id
        slug
        ...ProductPricingDetailsFragment
          @arguments(hideNonPublic: false, hideDrafts: false)
        ...ProductWithDetailsFragment
        ...ProductTypeTagFragment
      }
    `,
    productKey
  )

  const testid = `PaidProductTableRow.${product.slug}`

  return (
    <DiscoTable.Row testid={testid}>
      <TableCell>
        <ProductWithDetails productKey={product} testid={testid} />
      </TableCell>

      <TableCell>
        <ProductTypeTag productKey={product} />
      </TableCell>

      <TableCell>
        <ProductPricingDetails
          productKey={product}
          ignoreViewersPlan
          classes={{
            price: classes.price,
            details: classes.details,
          }}
        />
      </TableCell>
    </DiscoTable.Row>
  )
}

const useStyles = makeUseStyles((theme) => ({
  price: {
    ...theme.typography["body-sm"],
    fontWeight: 600,
  },
  details: {
    ...theme.typography["body-xs"],
  },
}))

export const PaidProductTableRowSkeleton = () => {
  return (
    <DiscoTable.Row>
      <TableCell>
        <ProductWithDetailsSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>

      <TableCell>
        <ProductPricingDetailsSkeleton />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default Relay.withSkeleton({
  component: observer(PaidProductTableRow),
  skeleton: PaidProductTableRowSkeleton,
})
