/**
 * @generated SignedSource<<7eec4b7471db04929973b615842efbcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationCurrency = "usd" | "aed" | "afn" | "all" | "amd" | "ang" | "aoa" | "ars" | "aud" | "awg" | "azn" | "bam" | "bbd" | "bdt" | "bgn" | "bmd" | "bnd" | "bob" | "brl" | "bsd" | "bwp" | "byn" | "bzd" | "cad" | "chf" | "cny" | "cop" | "crc" | "cve" | "czk" | "dkk" | "dop" | "dzd" | "egp" | "etb" | "eur" | "fjd" | "fkp" | "gbp" | "gel" | "gip" | "gmd" | "hkd" | "hnl" | "htg" | "huf" | "idr" | "ils" | "inr" | "isk" | "jmd" | "kgs" | "lak" | "lbp" | "lkr" | "lrd" | "lsl" | "mad" | "mdl" | "mkd" | "mmk" | "mnt" | "mop" | "mur" | "mvr" | "mxn" | "myr" | "mzn" | "nad" | "ngn" | "nio" | "nok" | "npr" | "nzd" | "pab" | "pen" | "pgk" | "php" | "pkr" | "pln" | "qar" | "ron" | "rsd" | "rub" | "sar" | "sbd" | "scr" | "sek" | "sgd" | "shp" | "srd" | "std" | "szl" | "thb" | "tjs" | "top" | "try" | "ttd" | "twd" | "uah" | "uyu" | "uzs" | "zar" | "zmw" | "%future added value";
export type UpdateOrganizationCurrencyInput = {
  currency: OrganizationCurrency;
};
export type ConnectStripeModalContent_UpdateCurrencyMutation$variables = {
  input: UpdateOrganizationCurrencyInput;
  returnTo: string;
};
export type ConnectStripeModalContent_UpdateCurrencyMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly currency: OrganizationCurrency;
      readonly stripeOauthAuthorizeUrl: string | null;
      readonly stripeAccountId: string | null;
      readonly hasStripeConnection: boolean;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type ConnectStripeModalContent_UpdateCurrencyMutation = {
  variables: ConnectStripeModalContent_UpdateCurrencyMutation$variables;
  response: ConnectStripeModalContent_UpdateCurrencyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnTo"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrganizationCurrencyMutationResponse",
    "kind": "LinkedField",
    "name": "updateOrganizationCurrency",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "returnTo",
                "variableName": "returnTo"
              }
            ],
            "kind": "ScalarField",
            "name": "stripeOauthAuthorizeUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stripeAccountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasStripeConnection",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectStripeModalContent_UpdateCurrencyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectStripeModalContent_UpdateCurrencyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9ced78699104fbefcabccf50765c3b07",
    "id": null,
    "metadata": {},
    "name": "ConnectStripeModalContent_UpdateCurrencyMutation",
    "operationKind": "mutation",
    "text": "mutation ConnectStripeModalContent_UpdateCurrencyMutation(\n  $input: UpdateOrganizationCurrencyInput!\n  $returnTo: String!\n) {\n  response: updateOrganizationCurrency(input: $input) {\n    node {\n      id\n      currency\n      stripeOauthAuthorizeUrl(returnTo: $returnTo)\n      stripeAccountId\n      hasStripeConnection\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee8ef0fbfd03402af78e4d96dd6e9ad8";

export default node;
