/**
 * @generated SignedSource<<a4de11ea09359d4c5f7acb9c735c8442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConnectStripeButtonQuery$variables = {
  id: string;
  returnTo: string;
};
export type ConnectStripeButtonQuery$data = {
  readonly organization: {
    readonly stripeOauthAuthorizeUrl?: string | null;
  } | null;
};
export type ConnectStripeButtonQuery = {
  variables: ConnectStripeButtonQuery$variables;
  response: ConnectStripeButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnTo"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "returnTo",
          "variableName": "returnTo"
        }
      ],
      "kind": "ScalarField",
      "name": "stripeOauthAuthorizeUrl",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectStripeButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectStripeButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c680984b12abc828f874174070b14795",
    "id": null,
    "metadata": {},
    "name": "ConnectStripeButtonQuery",
    "operationKind": "query",
    "text": "query ConnectStripeButtonQuery(\n  $id: ID!\n  $returnTo: String!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      stripeOauthAuthorizeUrl(returnTo: $returnTo)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c53e51135cfb6f06ad19213fca4d5b8";

export default node;
